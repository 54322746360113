import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, keywords, title, url, author }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            author
          }
        }
      }
    `
  )

  const metaKeywords = keywords || [
    "hotel dolina",
    "hotel dolina Veľký Krtíš",
    "hotel Veľký Krtíš",
    "ubytovanie Veľký Krtíš",
  ]

  return (
    <Helmet
      title={title || site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title || site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: description || site.siteMetadata.description,
        },
        {
          property: `og:url`,
          content: url || site.siteMetadata.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(
        metaKeywords && metaKeywords.length > 0
          ? { name: "keywords", content: metaKeywords.join(", ") }
          : []
      )}
    />
  )
}

export default Seo
